<template>
  <div style="display: contents">
    <transition name="cmd-menu-modal-transition">
      <div
        v-if="createProfileActive"
        :ref="name"
        :data-name="name"
        class="cmd-menu"
        :class="{
          'cmd-menu-animations': useAnimations,
          'scale-in-out': triggerAnimation,
        }"
        @click="handleOverlayClick($event)"
      >
        <!--           'cmd-menu-searching': loadingAsyncData && !totalItemsCount, -->
        <div class="cmd-menu-modal" :style="currentStep === 'review' ? 'max-height: 100%' : ''">
          <div class="cmd-menu-content">
            <div class="cmd-menu-search-wrapper">
              <input
                :ref="`${name}Search`"
                type="search"
                class="cmd-menu-search"
                :placeholder="placeholder"
                autocomplete="off"
                autofocus
                v-model="search"
                @keydown.esc="handleESC($event)"
                @keydown.enter.prevent="handleClick(selectedItem)"
                @keydown.down.prevent="handleArrowKeys($event, 'down')"
                @keydown.up.prevent="handleArrowKeys($event, 'up')"
                @keyup.down.prevent="stopArrowKeyInterval()"
                @keyup.up.prevent="stopArrowKeyInterval()"
              />
            </div>
            <div v-if="loadingData">
              <div class="cmd-menu-results">
                <div class="cmd-menu-section-links">
                  <SkeletonTheme color="#252526" highlight="#353538">
                    <a style="height: 40px" v-for="n in 4" :key="n">
                      <div style="height: 30px; width: 30px; margin-right: 0.575rem">
                        <Skeleton circle class="circle-avatar" />
                      </div>
                      <div class="d-flex flex-column align-items-center">
                        <div style="height: 13px">
                          <Skeleton width="300px" height="13px" />
                        </div>
                      </div>
                    </a>
                  </SkeletonTheme>
                </div>
              </div>
            </div>
            <div v-if="!totalItemsCount && !loadingData">
              <div class="cmd-menu-results-wrapper p-2">
                <p class="text-center text-muted mb-0">No results found</p>
              </div>
            </div>
            <div v-if="currentStep === 'review'" class="cmd-menu-results-wrapper" style="overflow: visible">
              <div v-if="profileCreate.student && 'id' in profileCreate.student" class="cmd-menu-results">
                <div class="cmd-menu-section-label">Student</div>
                <div class="cmd-menu-section-links">
                  <a style="cursor: default">
                    <div class="avatar">
                      <b-avatar
                        size="34"
                        :src="profileCreate.student.avatar"
                        :text="avatarText(profileCreate.student.text)"
                        variant="light-info"
                      />
                    </div>
                    <div>
                      <span class="text-nowrap">{{ profileCreate.student.text }}</span>
                      <div class="font-weight-light text-muted">
                        <span>{{ profileCreate.student.subtext }}</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="cmd-menu-results" v-if="profileCreate.cohort">
                <div class="cmd-menu-section-label">
                  {{ profileCreate.cohort.type === 'pathway' ? 'Pathway Cohort' : 'Course Section' }}
                </div>
                <div class="cmd-menu-section-links">
                  <a style="cursor: default">
                    <div class="avatar">
                      <b-avatar
                        size="34"
                        :src="profileCreate.cohort.avatar"
                        :text="avatarText(profileCreate.cohort.text)"
                        variant="light-info"
                        :rounded="true"
                      />
                    </div>
                    <div>
                      <span class="text-nowrap">{{ profileCreate.cohort.text }}</span>
                      <div class="font-weight-light text-muted">
                        <span>{{ profileCreate.cohort.subtext }}</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="cmd-menu-results" v-if="profileCreate.preferred">
                <div class="cmd-menu-section-label">
                  {{ profileCreate.preferred.type === 'pathway' ? 'Preferred Pathway' : 'Preferred Course' }}
                </div>
                <div class="cmd-menu-section-links">
                  <a style="cursor: default">
                    <div class="avatar">
                      <b-avatar
                        size="30"
                        :src="profileCreate.preferred.avatar"
                        :text="avatarText(profileCreate.preferred.text)"
                        variant="light-info"
                        :rounded="true"
                      />
                    </div>
                    <div class="text-nowrap">
                      <span>{{ profileCreate.preferred.text }}</span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="cmd-menu-results" v-if="profileCreate.funding">
                <div class="cmd-menu-section-label">Funding</div>
                <div class="cmd-menu-section-links">
                  <a style="cursor: default">
                    <!-- <div class="avatar">
                      <b-avatar
                        size="30"
                        :src="profileCreate.funding"
                        :text="avatarText(profileCreate.funding.text)"
                        variant="light-info"
                      />
                    </div> -->
                    <div class="text-nowrap">
                      <span>{{ profileCreate.funding.text }}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-if="totalItemsCount && !loadingData"
              class="cmd-menu-results-wrapper"
              :ref="`${name}Results`"
              :style="currentStep === 'review' ? 'overflow: visible' : ''"
            >
              <div v-for="(section, sIndex) in sections" :key="section.name" class="cmd-menu-results">
                <div v-if="section.name !== '_all'" class="cmd-menu-section-label">{{ section.name }}</div>
                <div class="cmd-menu-section-links">
                  <a
                    v-for="(item, iIndex) in section.items"
                    :key="item.id"
                    href="#"
                    class="justify-content-between"
                    :class="{ 'cmd-menu-results-selected': isItemSelected(sIndex, iIndex) }"
                    @click.prevent="handleClick(item)"
                    @mouseover="handleMouseOver(sIndex, iIndex)"
                    @mouseout="handleMouseOut"
                  >
                    <div class="d-flex align-items-center">
                      <div class="icon" v-if="item.icon || item.faIcon || item.lcIcon">
                        <feather-icon v-if="item.icon" :icon="item.icon || 'CircleIcon'" class="w-100 h-100" />
                        <font-awesome-icon v-if="item.faIcon" :icon="item.faIcon || 'CircleIcon'" class="w-100 h-100" />
                        <lucide-icon v-if="item.lcIcon" :name="item.lcIcon || CircleDashed" class="w-100 h-100" />
                      </div>
                      <div class="avatar" v-if="item.avatar || item.avatarText">
                        <b-avatar
                          :size="item.subtext ? 34 : 30"
                          :src="item.avatar"
                          :text="avatarText(item.avatarText || item.text)"
                          :rounded="item.avatarRounded || false"
                          variant="light-info"
                        />
                      </div>
                      <div class="">
                        <span class="text-nowrap">{{ item.text }}</span>
                        <div class="font-weight-light text-muted" v-if="item.subtext">
                          <span>{{ item.subtext }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="font-weight-light ml-50 text-capitalize text-nowrap"
                      v-if="item.description"
                      :class="item.descriptionVariant ? `text-${item.descriptionVariant}` : 'text-muted'"
                    >
                      {{ item.description }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="cmd-menu-footer">
              <p><span class="dismiss-message" @click="closeModal()">ESC</span> to close</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="overlay-fade">
      <div class="cmd-menu-overlay" v-if="createProfileActive" />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import Fuse from 'fuse.js';
import { onUnmounted, ref } from '@vue/composition-api';
import { BCard, BAvatar } from 'bootstrap-vue';
import { SkeletonTheme, Skeleton } from 'vue-loading-skeleton/src/index.js';
import useProfiles from '../useProfiles';
import { avatarText, title } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourseSections from '../../courses/sections/useCourseSections';
import usePathwayCohorts from '../../pathways/cohorts/usePathwayCohorts';
import store from '@/store';

export default {
  props: {
    createProfileActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    enrolledStudentIds: {
      type: Array,
      default: () => [],
      required: false,
    },
    selectedStudent: {
      type: Object,
      default: null,
    },
    selectedPathwayCohort: {
      type: Object,
      default: null,
    },
    selectedCourseSection: {
      type: Object,
      default: null,
    },
    redirectOnComplete: {
      type: Boolean,
      default: true,
    },

    profileId: {
      type: String,
    },
    profile: { type: Object },
    // restricts displaying to either pathways or courses.
    restrictToType: {
      type: String,
      enum: ['pathway', 'course'],
    },
    restrictToCourse: {
      type: String,
    },
    restrictToPathway: {
      type: String,
    },
    // previous cohort/section ids - used for cohort reassignment and section replacement.
    previousCohortId: {
      type: String,
    },
    previousSectionId: {
      type: String,
    },

    // allows you to define the mode of the profile add modal.
    // replace: uses a pre-existing profile, skips all steps related to student selection and profile creation.
    // standard: allows for student selection and profile creation.
    mode: {
      type: String,
      enum: ['replace', 'standard'],
      default: 'standard',
    },
  },
  components: {
    SkeletonTheme,
    Skeleton,
    BAvatar,
  },
  data() {
    return {
      name: 'ProfileAddv2',
      placeholder: '',
      currentStep: this.mode === 'replace' ? 'place' : 'student',

      loadingData: false,
      triggerAnimation: false,

      profileCreate: {
        existing: null,
        student: null,
        cohort: null,
        section: null, // a section, not a cohort.
        preferred: null,
        funding: null,
        enrolledCohorts: [],
        unassignedProfiles: [],
        allProfileIds: [],
      },
      searchResults: {
        students: [],
        cohorts: [],
        sections: [],
      },

      totalItemsCount: 0,
      studentItems: [],
      courseItems: [],
      fuseOptions: { keys: ['text'], threshold: 0.3 },

      arrowKeyDown: false,
      arrowKeyInterval: null,
      hoveredItem: null,
      search: '',
      results: [],
      currentItem: null,
      scale: 1.0,
      useAnimations: true,
      selectedSectionIndex: 0,
      selectedCommandIndexWithinSection: 0,
      sectionText: true,
      sections: [],
    };
  },
  computed: {
    selectedItem() {
      // Check if there's a valid selected section.
      if (this.selectedSectionIndex >= 0 && this.selectedSectionIndex < this.sections.length) {
        const selectedSection = this.sections[this.selectedSectionIndex];

        // Check if there's a valid selected command within the section.
        if (this.selectedCommandIndexWithinSection >= 0 && this.selectedCommandIndexWithinSection < selectedSection.items.length) {
          return selectedSection.items[this.selectedCommandIndexWithinSection];
        }
      }
      return null; // Return null if there's no valid selection.
    },
    commandItems() {
      switch (this.mode) {
        case 'replace':
          return { place: [], review: [] };
        default:
          return {
            student: [
              {
                id: 'student_create',
                section: 'Students',
                text: 'Create New Student',
                icon: 'PlusCircleIcon',
                action: 'newStudent',
              },
            ],
            profile: [
              {
                id: 'new_profile',
                section: 'Profiles',
                text: 'Create New Profile',
                icon: 'PlusCircleIcon',
                action: 'newProfile',
              },
              {
                id: 'return_student',
                section: 'Other',
                text: 'Return to Select Student',
                icon: 'ArrowLeftCircleIcon',
                sort: 'end',
                action: 'escKey',
              },
            ],
            place: [],
            preferred: [
              {
                id: 'return_place',
                section: 'Other',
                text: 'Return to Select Dates',
                icon: 'ArrowLeftCircleIcon',
                sort: 'end',
                action: 'escKey',
              },
            ],
            funding: [
              {
                id: 'funding_gi_bill',
                section: 'Assistance Programs',
                text: 'GI Bill',
                value: 'gi_bill',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_vrne',
                section: 'Assistance Programs',
                text: 'VR&E',
                value: 'vrne',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_army_cool',
                section: 'Assistance Programs',
                text: 'Army COOL',
                value: 'army_cool',
                action: 'selectFundingSource',

                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_airforce_cool',
                section: 'Assistance Programs',
                text: 'AirForce COOL',
                value: 'airforce_cool',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_mycaaa',
                section: 'Assistance Programs',
                text: 'MYCAA',
                value: 'mycaaa',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_vettec',
                section: 'Assistance Programs',
                text: 'VET-TEC Program',
                value: 'vettec',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_vrrap',
                section: 'Assistance Programs',
                text: 'VRRAP',
                value: 'vrrap',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_wioa',
                section: 'Assistance Programs',
                text: 'WIOA',
                value: 'wioa',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'funding_open',
                section: 'General Enrollments',
                text: 'Open',
                value: 'open',
                action: 'selectFundingSource',
                // "icon": "UserCircleIcon"
              },
              {
                id: 'funding_corp',
                section: 'General Enrollments',
                text: 'Corporate',
                value: 'corp',
                action: 'selectFundingSource',
                // "icon": "HelpCircleIcon"
              },
              {
                id: 'return_place',
                section: 'Other',
                text: 'Return to Select Dates',
                icon: 'ArrowLeftCircleIcon',
                sort: 'end',
                action: 'escKey',
              },
            ],
            review: [
              {
                id: 'return_funding',
                section: 'Actions',
                text: 'Return to Funding Sources',
                icon: 'ArrowLeftCircleIcon',
                sort: 'end',
                action: 'escKey',
              },
            ],
          };
      }
    },
  },
  emits: ['refetch-data', 'update:create-profile-active'],
  watch: {
    async search(value) {
      // Instantly use Fuse for filtering
      this.filterCommandItems(value);
    },
    // commandItems: {
    //   immediate: true,
    //   handler(actions) {
    //     this.filterCommandItems(this.search);
    //   },
    // },
    createProfileActive(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
        this.search = '';
        if (this.selectedStudent) {
          this.currentStep = 'place';
        } else {
          this.currentStep = 'student';
        }
        this.$nextTick(() => {
          this.scrollToTop();
          this.$refs[`${this.name}Search`].focus();
        });
      } else {
        document.body.style.overflow = '';
        this.resetSelection();
      }
    },
    currentStep(value) {
      this.checkStep();
    },
  },
  methods: {
    checkStep() {
      this.triggerAnimation = true;
      setTimeout(() => {
        this.triggerAnimation = false;
      }, 100);

      if (this.currentStep === 'student') {
        this.profileCreate.unassignedProfiles = [];
        this.profileCreate.existing = null;
        this.profileCreate.preferred = null;
        this.profileCreate.cohort = null;
        this.parseProps();
        if (this.selectedStudent) {
          this.getStudentCohorts();
          this.currentStep = 'profile';
          return;
        }
        this.placeholder = 'Select a student...';
        this.loadingData = true;

        this.filterCommandItems();
      } else if (this.currentStep === 'profile') {
        this.profileCreate.existing = null;
        this.placeholder = 'Choose roster profile...';
        this.loadingData = true;
        this.filterCommandItems();
      } else if (this.currentStep === 'place') {
        this.profileCreate.preferred = null;
        this.placeholder = 'Choose cohort or section...';
        this.loadingData = true;
        this.filterCommandItems();
      } else if (this.currentStep === 'funding') {
        this.placeholder = 'Choose funding source...';
        this.filterCommandItems();
      } else if (this.currentStep === 'preferred') {
        this.profileCreate.cohort = null;
        this.placeholder = 'Choose preferred program...';
        this.loadingData = true;
        this.filterCommandItems();
      } else if (this.currentStep === 'review') {
        this.placeholder = `Review profile ${this.mode === 'replace' ? 'modification' : 'creation'}...`;
        this.filterCommandItems();
      }

      if (this.createProfileActive) {
        this.$nextTick(() => {
          this.search = '';
          this.hoveredItem = null;
          this.scrollToTop();
          this.$refs[`${this.name}Search`].focus();
        });
      }
    },
    scrollToTop() {
      const resultsWrapper = this.$refs[`${this.name}Results`];
      if (resultsWrapper) {
        resultsWrapper.scrollTop = 0;
      }
    },
    async filterCommandItems(search) {
      // Use Fuse to filter command items instantly
      const searchResults = [];

      if (search) {
        const fuse = new Fuse(this.commandItems[this.currentStep], this.fuseOptions);
        const actions = fuse.search(search).map((result) => result.item);
        searchResults.push(...actions);
      } else {
        searchResults.push(...this.commandItems[this.currentStep]);
      }

      if (this.currentStep === 'student') {
        const students = await this.filterStudents(search);
        searchResults.push(...students);
      }

      if (this.currentStep === 'profile') {
        const profiles = this.profileCreate.unassignedProfiles;
        searchResults.push(...profiles);
      }

      if (this.currentStep === 'place') {
        console.log('> running place step with param', {
          search,
          restrictToCourse: this.restrictToCourse,
          restrictToPathway: this.restrictToPathway,
        });

        const [cohorts, sections] = await Promise.all([
          !this.restrictToCourse && this.filterPathwayCohorts(search),
          !this.restrictToPathway && this.filterCourseSections(search),
        ]);

        console.log('> providing the following: ', { cohorts, sections });

        searchResults.push(...(cohorts || []), ...(sections || []));

        if (this.mode !== 'replace') {
          if (!this.profileCreate.existing) {
            searchResults.push({
              id: 'return_student',
              section: 'Other',
              text: 'Return to Select Student',
              icon: 'ArrowLeftCircleIcon',
              sort: 'end',
              action: 'escKey',
            });
            searchResults.push({
              id: 'place_undecided',
              section: 'Other',
              text: 'Undecided Dates',
              icon: 'HelpCircleIcon',
              sort: 'end',
              action: 'goToPreferredProgram',
            });
          } else {
            searchResults.push({
              id: 'return_profile',
              section: 'Other',
              text: 'Return to Select Profile',
              icon: 'ArrowLeftCircleIcon',
              sort: 'end',
              action: 'escKey',
            });
          }
        }
      }

      if (this.currentStep === 'preferred') {
        const courses = await this.filterCourses(search);
        const pathways = await this.filterPathways(search);
        searchResults.push(...pathways, ...courses);
      }

      if (this.currentStep === 'review') {
        searchResults.push({
          id: this.profileCreate.existing ? 'update_profile' : 'create_profile',
          section: 'Actions',
          text: `${this.profileCreate.existing ? 'Update' : 'Create'} Roster Profile`,
          icon: 'CheckCircleIcon',
          action: this.profileCreate.existing ? 'updateProfile' : 'createProfile',
        });
      }

      // Move items with sort: 'end' to the end of the array
      searchResults.sort((a, b) => {
        if (a.sort === 'end' && b.sort !== 'end') {
          return 1;
        } else if (a.sort !== 'end' && b.sort === 'end') {
          return -1;
        } else {
          return 0;
        }
      });

      this.results = [...searchResults];
      this.totalItemsCount = this.results.length;
      this.loadingData = false;
      this.updateSections();
    },
    async filterStudents(search) {
      try {
        const response = await this.$store.dispatch('studentStoreModule/fetchStudents', {
          search,
        });
        const results = response.data.results;
        const students = results.map((student) => {
          const alreadyEnrolled = this.enrolledStudentIds.includes(student.id);
          return {
            id: student.id,
            section: 'Students',
            text: student.fullName,
            subtext: student.email,
            description: alreadyEnrolled ? 'Student Already Enrolled' : student.myIpId,
            descriptionVariant: alreadyEnrolled ? 'danger' : 'muted',
            avatar: student.avatar,
            action: alreadyEnrolled ? '' : 'selectStudent',
          };
        });
        return students;
      } catch (error) {
        return [];
      }
    },
    mapRosterProfile(profile) {
      const name = profile.preferred
        ? profile.preferred.pathway
          ? profile.preferred.pathway.name
          : profile.preferred.course
          ? profile.preferred.course.name
          : 'No Preferred Program'
        : profile.metadata.certificate
        ? profile.metadata.certificate
        : 'No Preferred Program';

      const avatar = profile.preferred
        ? profile.preferred.pathway
          ? profile.preferred.pathway.avatar
          : profile.preferred.course
          ? profile.preferred.course.avatar
          : undefined
        : undefined;

      return {
        id: profile.id,
        section: 'Profiles',
        text: name,
        subtext: `Existing Pending Profile - ${this.title(profile.attributes.fundingSource)}`,
        description: this.title(profile.status),
        descriptionVariant: this.resolveProfileStatusVariant(profile.status),
        avatar,
        avatarText: name !== 'No Preferred Program' ? name : undefined,
        funding: {
          id: `funding_${profile.attributes.fundingSource}`,
          text: this.title(profile.attributes.fundingSource),
          value: profile.attributes.fundingSource,
        },
        action: 'selectProfile',
      };
    },
    async getUnassignedProfiles(search) {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchProfiles', {
          page: 1,
          limit: 100,
          student: this.profileCreate.student.id,
          search,
          session: 'null',
        });
        const results = response.data.results;

        if (results.length === 0) {
          this.currentStep = 'place';
          return;
        }

        const profiles = results.map(this.mapRosterProfile);
        console.log(profiles);
        this.profileCreate.unassignedProfiles = profiles;
        this.currentStep = 'profile';
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async filterCourseSections(search) {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchCourseSectionList', {
          populate: 'course,courseCohort',
          sortBy: 'startDate:desc',
          search,
          course: this.restrictToCourse || undefined,
        });
        const results = response.data.results;
        const sections = results.map((section) => {
          const alreadyInSection = this.profileCreate.allProfileIds.some((profileId) => section.profiles.includes(profileId));
          return {
            id: section.id,
            section: 'Course Sections',
            text: this.resolveCourseSectionName(section),
            description: alreadyInSection ? 'Already Enrolled In Cohort' : `${section.profiles.length} students` + ` - ${section.status}`,
            descriptionVariant: alreadyInSection ? 'danger' : this.resolveCourseSectionStatusVariant(section.status),
            avatar: section.course.avatar,
            avatarText: section.course.name,
            avatarRounded: true,
            subtext: section.course.name,
            action: alreadyInSection ? '' : 'selectCourseSection',
            cohort: section.courseCohort,
          };
        });
        return sections;
      } catch (error) {
        return [];
      }
    },
    async filterPathwayCohorts(search) {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayCohortList', {
          populate: 'pathway',
          sortBy: 'startDate:desc',
          search,
          pathway: this.restrictToPathway || undefined,
        });
        const results = response.data.results;
        const cohorts = results.map((cohort) => {
          const alreadyEnrolled = this.profileCreate.enrolledCohorts.includes(cohort.id);
          return {
            id: cohort.id,
            section: 'Pathway Cohort',
            text: this.resolvePathwayCohortName(cohort),
            description: alreadyEnrolled ? 'Already Enrolled In Cohort' : `${cohort.profiles.length} students` + ` - ${cohort.status}`,
            descriptionVariant: alreadyEnrolled ? 'danger' : this.resolvePathwayCohortStatusVariant(cohort.status),
            subtext: cohort.pathway.name,
            avatar: cohort.pathway.avatar,
            avatarText: cohort.pathway.name,
            avatarRounded: true,
            action: alreadyEnrolled ? '' : 'selectPathwayCohort',
            cohort,
          };
        });
        return cohorts;
      } catch (error) {
        return [];
      }
    },
    async filterCourses(search) {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchCourseList', {
          search,
        });
        const results = response.data.results;
        const courses = results.map((course) => {
          return {
            id: course.id,
            section: 'Courses',
            text: course.name,
            avatar: course.avatar,
            avatarText: course.name,
            avatarRounded: true,
            action: 'selectPreferredCourse',
            program: course,
          };
        });
        return courses;
      } catch (error) {
        return [];
      }
    },
    async filterPathways(search) {
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayList', {
          populate: 'pathway',
          sortBy: 'startDate:desc',
          search,
        });
        const results = response.data.results;
        const pathways = results.map((pathway) => {
          return {
            id: pathway.id,
            section: 'Pathways',
            text: pathway.name,
            avatar: pathway.avatar,
            avatarText: pathway.name,
            avatarRounded: true,
            action: 'selectPreferredPathway',
            program: pathway,
          };
        });
        return pathways;
      } catch (error) {
        return [];
      }
    },
    parseProps() {
      this.parseSelectedStudent();
      this.parseSelectedProfile();
      this.parseSelectedPathwayCohort();
      this.parseSelectedCourseSection();
    },
    parseSelectedStudent(student = this.selectedStudent) {
      console.log('> parsing selected student: ', student, this.selectedStudent);
      if (student) {
        this.profileCreate.student = {
          id: student.id,
          section: 'Students',
          text: student.fullName,
          subtext: student.email,
          description: student.myIpId,
          avatar: student.avatar,
          action: 'selectStudent',
        };

        this.getStudentCohorts()
          .then(() => this.mode === 'replace' && this.filterCommandItems())
          .catch((error) => {
            console.error(`> failed to retrieve student cohorts post-parse selected student: ${error.message}`, error);
          });
      }
    },
    parseSelectedProfile(profile = this.profile) {
      if (profile) {
        this.profileCreate.existing = this.mapRosterProfile(profile);
        console.log('> using existing profile: ', this.profileCreate.existing);
      }
    },
    parseSelectedPathwayCohort(cohort = this.selectedPathwayCohort) {
      if (cohort) {
        this.profileCreate.cohort = {
          id: cohort.id,
          section: 'Pathway Cohort',
          text: this.resolvePathwayCohortName(cohort),
          description: `${cohort.profiles.length} students` + ` - ${cohort.status}`,
          descriptionVariant: this.resolvePathwayCohortStatusVariant(cohort.status),
          subtext: cohort.pathway.name,
          avatar: cohort.pathway.avatar,
          avatarText: cohort.pathway.name,
          action: 'selectPathwayCohort',
          cohort,
        };
      }
    },
    parseSelectedCourseSection(section = this.selectedCourseSection) {
      if (section) {
        this.profileCreate.cohort = {
          id: section.id,
          section: 'Course Sections',
          text: this.resolveCourseSectionName(section),
          description: `${section.profiles.length} students` + ` - ${section.status}`,
          descriptionVariant: this.resolveCourseSectionStatusVariant(section.status),
          avatar: section.course.avatar,
          avatarText: section.course.name,
          subtext: section.course.name,
          action: 'selectCourseSection',
          cohort: section.courseCohort,
        };
      }
    },
    updateSections() {
      const items = this.results;
      const sectionedItems = items.reduce((sections, item) => {
        const section = item.section || '_all';
        if (!sections[section]) {
          sections[section] = [];
        }
        sections[section].push(item);
        return sections;
      }, {});
      this.sections = Object.keys(sectionedItems).map((name) => ({ name, items: sectionedItems[name] }));
      this.resetSelection();
    },
    handleMouseOver(sectionIndex, itemIndex) {
      if (this.arrowKeyDown) return;

      // Update the selected indexes based on mouse hover
      this.selectedSectionIndex = sectionIndex;
      this.selectedCommandIndexWithinSection = itemIndex;

      // Deselect previously hovered item if any
      if (this.hoveredItem) {
        const { sectionIndex: prevSectionIndex, itemIndex: prevItemIndex } = this.hoveredItem;
        this.sections[prevSectionIndex].items[prevItemIndex].hovered = false;
      }

      // Select the current item and mark it as hovered
      this.hoveredItem = { sectionIndex, itemIndex };
      this.sections[sectionIndex].items[itemIndex].hovered = true;
    },
    handleMouseOut() {
      this.hoveredItem = null;
    },
    isItemSelected(sectionIndex, itemIndex) {
      return (
        (this.selectedSectionIndex === sectionIndex && this.selectedCommandIndexWithinSection === itemIndex) ||
        (this.hoveredItem && this.hoveredItem.sectionIndex === sectionIndex && this.hoveredItem.itemIndex === itemIndex)
      );
    },
    handleArrowKeys(e, direction) {
      this.hoveredItem = null;

      const sectionsLength = this.sections.length;
      const currentSection = this.sections[this.selectedSectionIndex];
      const itemsLength = currentSection.items.length;

      if (direction === 'down') {
        this.selectedCommandIndexWithinSection++;
        if (this.selectedCommandIndexWithinSection >= itemsLength) {
          this.selectedCommandIndexWithinSection = 0;
          this.selectedSectionIndex = (this.selectedSectionIndex + 1) % sectionsLength;
        }
      } else if (direction === 'up') {
        this.selectedCommandIndexWithinSection--;
        if (this.selectedCommandIndexWithinSection < 0) {
          this.selectedSectionIndex = (this.selectedSectionIndex - 1 + sectionsLength) % sectionsLength;
          this.selectedCommandIndexWithinSection = this.sections[this.selectedSectionIndex].items.length - 1;
        }
      }

      this.$nextTick(() => {
        if (this.createProfileActive) {
          this.scrollToSelectedCommand();
        }
      });

      // Start the interval only if it's not already running
      if (!this.arrowKeyInterval) {
        this.startArrowKeyInterval(e, direction);
      }

      e.preventDefault();
    },
    startArrowKeyInterval(e, direction) {
      // Set a flag to indicate that the arrow key is held down
      this.arrowKeyDown = true;

      // Start the interval to repeatedly call handleArrowKeys when the key is held down
      setTimeout(() => {
        // Check if the arrow key is still held down
        if (this.arrowKeyDown) {
          this.arrowKeyInterval = setInterval(() => {
            this.handleArrowKeys(e, direction);
          }, 10000); // Change the interval time to 200ms
        }
      }, 500); // Wait for 500ms before starting the interval
    },
    stopArrowKeyInterval() {
      // Stop the interval when the key is released
      clearInterval(this.arrowKeyInterval);
      this.arrowKeyInterval = null;

      // Reset the flag indicating that the arrow key is held down
      this.arrowKeyDown = false;
    },
    scrollToSelectedCommand() {
      const container = this.$refs[`${this.name}Results`];

      const selectedCommand = this.$el.querySelector('.cmd-menu-results-selected');
      if (container && selectedCommand) {
        const containerRect = container.getBoundingClientRect();
        const selectedRect = selectedCommand.getBoundingClientRect();
        if (selectedRect.top < containerRect.top) {
          container.scrollTop -= containerRect.top - selectedRect.top + 100;
        } else if (selectedRect.bottom > containerRect.bottom) {
          container.scrollTop += selectedRect.bottom - containerRect.bottom + 100;
        }
      }
    },
    async getStudentCohorts() {
      if (!this.profileCreate.student) {
        return (this.profileCreate.enrolledCohorts = []);
      }
      return store
        .dispatch('rosterStoreModule/fetchProfiles', {
          page: 1,
          limit: 100,
          student: this.profileCreate.student.id,
        })
        .then((response) => {
          const { results } = response.data;
          const cohortIds = results.map((result) => result.session?.id).filter((id) => id);
          this.profileCreate.allProfileIds.push(...results.map((profile) => profile.id || profile._id)); // can never fucking tell which it'll be lol
          this.profileCreate.enrolledCohorts = cohortIds;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching profiles',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    handleESC(e) {
      if (e) {
        e.preventDefault();
      }

      if (this.currentStep === 'student') {
        this.closeModal();
      } else if (this.currentStep === 'place') {
        if (this.selectedStudent) {
          this.closeModal();
          return;
        }
        if (this.profileCreate.existing) {
          this.currentStep = 'profile';
          return;
        }
        this.currentStep = 'student';
      } else if (this.currentStep === 'profile') {
        this.currentStep = 'student';
      } else if (this.currentStep === 'funding') {
        if (this.selectedPathwayCohort || this.selectedCourseSection) {
          this.currentStep = 'student';
          return;
        }
        if (this.profileCreate.preferred) {
          this.currentStep = 'preferred';
        } else {
          this.currentStep = 'place';
        }
      } else if (this.currentStep === 'preferred') {
        this.currentStep = 'place';
      } else if (this.currentStep === 'review') {
        this.currentStep = 'funding';
      }
    },
    async handleClick(item) {
      const { action } = item;

      if (action === 'selectStudent') {
        this.profileCreate.student = item;
        if (this.selectedPathwayCohort || this.selectedCourseSection) {
          this.currentStep = 'funding';
          return;
        }
        await this.getStudentCohorts();
        await this.getUnassignedProfiles();
      }

      if (action === 'selectProfile') {
        this.profileCreate.existing = item;
        this.currentStep = 'place';
      }

      if (action === 'selectPathwayCohort') {
        this.profileCreate.cohort = item;

        // if we're in the replace mode, skip all other steps and immediately go to review
        if (this.mode === 'replace') {
          this.currentStep = 'review';
          return;
        }

        if (this.profileCreate.existing) {
          this.currentStep = 'review';
          this.profileCreate.funding = this.profileCreate.existing.funding;
        } else {
          this.currentStep = 'funding';
        }
      }

      if (action === 'selectCourseSection') {
        this.profileCreate.cohort = item;
        this.profileCreate.section = item;

        // if we're in the replace mode, skip all other steps and immediately go to review
        if (this.mode === 'replace') {
          this.currentStep = 'review';
          return;
        }

        if (this.profileCreate.existing) {
          this.currentStep = 'review';
          this.profileCreate.funding = this.profileCreate.existing.funding;
        } else {
          this.currentStep = 'funding';
        }
      }

      if (action === 'selectPreferredCourse') {
        this.profileCreate.preferred = { type: 'course', ...item };
        this.currentStep = 'funding';
      }

      if (action === 'selectPreferredPathway') {
        this.profileCreate.preferred = { type: 'pathway', ...item };
        this.currentStep = 'funding';
      }

      if (action === 'selectFundingSource') {
        this.profileCreate.funding = item;
        this.currentStep = 'review';
      }

      if (action === 'goToPreferredProgram') {
        this.currentStep = 'preferred';
      }

      if (action === 'newStudent') {
        this.$router.push({ path: '/students?newStudent=true' });
      }

      if (action === 'newProfile') {
        this.currentStep = 'place';
      }

      if (action === 'escKey') {
        this.handleESC();
      }

      if (action === 'createProfile') {
        this.createEntity();
      }

      if (action === 'updateProfile') {
        this.updateEntity();
      }
    },
    resetSelection() {
      this.selectedSectionIndex = 0;
      this.selectedCommandIndexWithinSection = 0;
    },
    closeModal() {
      this.$emit('update:create-profile-active', false);
    },
    handleOverlayClick(e) {
      if (e.target === this.$refs[this.name]) {
        this.closeModal();
      }
    },
    async updateEntity() {
      console.log(this.profileCreate);

      if (!this.profileCreate || !this.profileCreate.student || !this.profileCreate.existing || !this.profileCreate.cohort) {
        console.log('invalid student or existing profile or cohort');
        return false;
      }

      // if we're in replace mode, then we need to replace the student's existing section with the newly selected one.
      if (this.mode === 'replace') {
        const { section } = this.profileCreate;

        if (!section || !section.id) {
          console.log('invalid section:', section);
          return false;
        }

        const payload = {
          sectionId: section.id,
          profileIds: [this.profileCreate.existing.id],
          options: { replaces: this.selectedCourseSection.id },
        };

        console.log('> using payload to reassign', payload);

        store
          .dispatch('rosterStoreModule/addStudentsToSection', payload)
          .then(() => {
            this.$emit('refetch-data', true);
            this.$emit('update:create-profile-active', false);

            return this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'EditIcon',
                variant: 'success',
                title: "Profile's section reassigned",
                text: `This student has been assigned to this ${section.name}`,
              },
            });
          })
          .catch((err) => {
            console.error("failed to replace profile's section", err);
          });

        return;
      }

      const cohort = this.profileCreate.cohort.cohort;
      const storeAction = cohort.type === 'pathway' ? 'addStudentsToPathwayCohort' : 'addStudentsToCourseCohort';
      store
        .dispatch(`rosterStoreModule/${storeAction}`, {
          cohortId: this.profileCreate.cohort.cohort.id,
          profileIds: [this.profileCreate.existing.id],
        })
        .then(() => {
          this.$emit('refetch-data');
          this.$emit('update:create-profile-active', false);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',
              title: `Added profile to ${cohort.type}`,
              text: `This student has been assigned to this ${cohort.type}`,
            },
          });
        })
        .catch((err) => {
          console.error(`failed to add profile to ${cohort.type}`, err);
        });
    },
    async createEntity() {
      if (!this.profileCreate || !this.profileCreate.student) {
        console.log('invalid student');
        return false;
      }

      const payload = {
        student: this.profileCreate.student.id,
        status: this.status,
      };

      console.log(this.profileCreate);

      if (this.profileCreate.preferred) {
        const preferred = {
          pathway: null,
          course: null,
        };
        if (this.profileCreate.preferred.type === 'pathway') {
          preferred.pathway = this.profileCreate.preferred.program.id;
        }
        if (this.profileCreate.preferred.type === 'course') {
          preferred.course = this.profileCreate.preferred.program.id;
        }
        payload.preferred = preferred;
      }

      if (this.profileCreate.cohort) {
        payload.session = this.profileCreate.cohort.cohort.id;
      }

      if (this.profileCreate.funding) {
        payload.attributes = { fundingSource: this.profileCreate.funding.value };
      }

      store
        .dispatch('rosterStoreModule/addProfile', payload)
        .then((response) => {
          console.log('created profile', response);
          const { data: profile } = response;

          this.$emit('refetch-data');
          this.$emit('update:create-profile-active', false);

          if (this.redirectOnComplete) {
            this.$router.push({ name: 'apps-roster-profile-edit', params: { id: profile.id } });
          }
        })
        .catch((error) => {
          console.error('failed to create profile', error);
        });
    },
  },
  mounted() {
    this.parseProps();
    this.checkStep();
  },
  setup(props) {
    const { statusOptions, visibilityOptions, resolveProfileStatusVariant } = useProfiles();
    const { resolveCourseSectionName, resolveCourseSectionStatusVariant } = useCourseSections();
    const { resolvePathwayCohortName, resolvePathwayCohortStatusVariant } = usePathwayCohorts();

    return {
      avatarText,
      title,

      resolvePathwayCohortStatusVariant,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionName,
      resolvePathwayCohortName,
      resolveProfileStatusVariant,

      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.cmd-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4001 !important;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overscroll-behavior: contain;
  color: var(--text-muted);

  &-searching .cmd-menu-modal::after {
    background: linear-gradient(60deg, #0053ff, #0017b7, #0053ff, #009eff, #0017b7, #00b0ff);
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  &-modal {
    padding: 0;
    max-width: 600px;
    max-height: min(440px, calc(100vh - 20% - 2rem));
    width: 95%;
    top: 22.5%;
    left: 50%;
    transform: translate(-50%, -00%);
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    transition: transform 0.05s ease;
    will-change: transform;
    transform-origin: center;

    &:after {
      content: '';
      position: absolute;
      top: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      height: calc(100% + var(--border-width) * 2);
      width: calc(100% + var(--border-width) * 2);
      border-radius: var(--border-radius);
      z-index: -1;
      background: #292b38;
      box-shadow: 0 6px 30px 6px rgb(0 0 0 / 11%);
    }
  }

  &-content {
    display: grid;
    padding: 0;
    background: var(--background);
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: var(--border-radius);
    overflow: hidden;
    will-change: transform;
    transform-origin: center center;
  }

  &-header {
    border-bottom: 1px solid var(--border);
  }

  &-search {
    flex-grow: 1;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 1rem 1rem 0.85rem 1rem;
    color: #dfe0e3;
    font-family: inherit;
    font-size: 1.25rem;
    line-height: 1.55;

    &-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid var(--border);
    }
  }

  &-tag {
    border-radius: var(--border-radius);
    padding: 0.5rem 0.8rem;
    background: var(--background-light);
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    font-weight: 500;

    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }

  &-results {
    padding: 0.5rem 0 0.6rem 0;

    &-wrapper {
      overflow-y: auto;
    }

    &-selected {
      outline: 0;
      background: var(--hover);
      color: var(--text-default);
    }

    a {
      min-height: 40px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.3rem 1rem 0.3rem 0.55rem;
      box-sizing: border-box;
      color: var(--text-default);
      text-decoration: none;
      border-left: 2px solid transparent;
      border-radius: 0.5rem;
      transition: none !important;

      .icon,
      .avatar {
        display: flex;
        margin-right: 0.575rem;
        border-radius: 0 !important;
        background: transparent !important;
      }

      .icon {
        width: 16px;
        margin-left: 0.45rem;
      }

      &:focus,
      &:hover {
        outline: 0;
        color: var(--text-default);
      }
    }
  }

  &-footer {
    border-top: 1px solid var(--border);
    font-size: 0.8rem;
    color: var(--text-default);

    p {
      margin: 0;
      padding: 0.5rem 0.5rem;
    }

    .dismiss-message {
      background: var(--background-light);
      border-radius: 5px;
      padding: 0.2rem 0.5rem;
      margin-left: 0.5rem;
      font-family: monospace;
    }
  }

  input {
    &::-webkit-input-placeholder {
      color: var(--text-muted);
    }

    &::-moz-placeholder {
      color: var(--text-muted);
    }

    &::-ms-placeholder {
      color: var(--text-muted);
    }

    &::placeholder {
      color: var(--text-muted);
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &-section {
    &-links {
      display: grid;
      padding: 0 0.5rem;
      gap: 0.2rem;
    }

    &-label {
      font-size: 0.8rem;
      padding: 0 1rem 0.2rem 1rem;
      box-sizing: border-box;
      color: #9b9ba4;
    }
  }
}

.cmd-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(0px);
}

.overlay-fade-enter-active,
.overlay-fade-leave-active,
.cmd-menu-modal-transition-enter-active,
.cmd-menu-modal-transition-leave-active {
  transition: opacity 0.1s ease;

  &.cmd-menu-modal-transition-enter-active,
  &.cmd-menu-modal-transition-leave-active {
    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.overlay-fade-enter,
.overlay-fade-leave-to,
.cmd-menu-modal-transition-enter,
.cmd-menu-modal-transition-leave-to {
  opacity: 0;

  &.cmd-menu-modal-transition-enter,
  &.cmd-menu-modal-transition-leave-to {
    transform: translateY(15px) scale(0.92);
  }
}

.overlay-fade-enter-to,
.cmd-menu-modal-transition-enter-to {
  opacity: 1;

  &.cmd-menu-modal-transition-enter-to {
    transform: translateY(0) scale(1);
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.scale-in-out {
  animation: scaleInOutAnimation 0.35s cubic-bezier(0.33, 0, 0.67, 1) forwards;
}

@keyframes scaleInOutAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96); /* Subtly scale down for a smoother effect */
  }
}
</style>
<style>
.cmd-menu {
  .pu-skeleton {
    line-height: normal !important;
  }

  .circle-avatar {
    .pu-skeleton {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
</style>
